import { useIntl } from "gatsby-plugin-intl"

export default function useItl() {
  let intl = useIntl()

  intl.translate = (object, key) => {
    const translation = object[`${key}_${intl.locale}`]

    if (!translation) {
      console.error(`${key} not found on: `, object)
    }

    return translation
  }

  return intl
}
