import React, {useState} from "react"
import Rainbowbar from "./rainbowbar"
import LogoNl from "../images/logo--nl.svg"
import FooterStyle from "../less/footer.module.less"
import Container from "./container"
import PartnersOne from "../images/partner--1.svg"
import PartnersTwo from "../images/partner--2.svg"
import Twitter from "../images/twitter.svg"
import Facebook from "../images/facebook.svg"
import Linkedin from "../images/linkedin.svg"
import Youtube from "../images/youtube.svg"
import {useIntl} from "react-intl";
import {changeLocale, IntlContextConsumer, Link} from "gatsby-plugin-intl";

const Footer = () => {
  const intl = useIntl();
  const [localeNames] = useState({
    nl: "NL",
    fr: "FR"
  });

  return (
    <footer role="contentinfo">
      <section className={FooterStyle.header}>
        <Container size={"small"}>
          <div className={FooterStyle.headerContent}>
            <a href={intl.formatMessage({id: 'link_financesbudgetbrussels'})}
               target="_blank"
               rel="noopener noreferrer">
              <img className={FooterStyle.bxlLogo} src={LogoNl}
                   alt={intl.formatMessage({id: 'alt_financesbudgetbrusselslogo'})}/>
            </a>
            <div className={FooterStyle.logos}>
              <a href={intl.formatMessage({id: 'link_twitter'})} target="_blank"
                 rel="noopener noreferrer">
                <img src={Twitter} alt={'Twitter logo'}/>
              </a>

              <a href={intl.formatMessage({id: 'link_facebook'})}
                 target="_blank" rel="noopener noreferrer">
                <img src={Facebook} alt={'Facebook logo'}/>
              </a>

              <a href={intl.formatMessage({id: 'link_linkedin'})}
                 target="_blank" rel="noopener noreferrer">
                <img src={Linkedin} alt={'LinkedIn logo'}/>
              </a>

              <a href={intl.formatMessage({id: 'link_youtube'})} target="_blank"
                 rel="noopener noreferrer">
                <img src={Youtube} alt={'Youtube logo'}/>
              </a>
            </div>
          </div>
        </Container>
      </section>

      <section className={FooterStyle.partners}>
        <Container size={"small"}>
          <div className={FooterStyle.partnerContainers}>
            <div>
              <h4 className={FooterStyle.partnersTitle}>{intl.formatMessage({id: 'footer_partners_title'})}</h4>
              <div className={FooterStyle.partnerLogos}>
                <a href="/">
                  <img src={PartnersOne} alt=''/>
                </a>
                <a href="/">
                  <img src={PartnersTwo} alt=''/>
                </a>
              </div>
            </div>

            <div>
              <h4
                className={FooterStyle.partnersTitle}>{intl.formatMessage({id: 'footer_partners_title'})}</h4>
              <div className={FooterStyle.partnerLogos}>
                <div>
                  <a href="/">
                    <img src={PartnersOne} alt={''}/>
                  </a>
                  <a href="/">
                    <img src={PartnersTwo} alt={''}/>
                  </a>
                </div>
                <div>
                  <a href="/">
                    <img src={PartnersOne} alt={''}/>
                  </a>
                  <a href="/">
                    <img src={PartnersTwo} alt={''}/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <hr/>
      <section>
        <Container size={"small"}>
          <div className={FooterStyle.bottom}>
            <ul className={FooterStyle.items}>
              <li style={{flexShrink: '0'}}>&#169; {new Date().getFullYear()}, {intl.formatMessage({id: 'footer_author'})}</li>
              <li><Link to={'#'}>Gebruiksvoorwaarden</Link>
              </li>
              <li><Link to={'#'}>Privacy</Link>
              </li>
              <li><Link to={'#'}>Toegankelijkheid</Link>
              </li>
              <li><Link to={'#'}>Klachten</Link>
              </li>
              <li><Link to={'#'}>Credits</Link>
              </li>
            </ul>

            <div className={FooterStyle.lang}>
              {/* Todo: make this accessible with a select */}
              <IntlContextConsumer>
                {
                  ({languages, language: currentLocale}) =>
                    languages.map((localeSlug) => (
                      <a style={{
                        marginRight: '5px',
                        color: localeSlug === currentLocale ? '' : 'lightgray'
                      }}
                         href={''}
                         key={localeSlug}
                         role='button'
                         onClick={() => changeLocale(localeSlug)}>
                        {localeNames[localeSlug]}
                      </a>
                    ))
                }
              </IntlContextConsumer>
            </div>
          </div>
        </Container>
      </section>
      <Rainbowbar/>
    </footer>
  )
}

export default Footer
