/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {graphql, useStaticQuery} from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "../less/main.module.less"
import Navigation from "./navigation";
import Hero from "./hero";

const Layout = ({children, heroImage, heroBgColor, heroTitle, heroSubtitle, heroAltText, heroTextColor, showHero, heroSize}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title,
          author
        }
      }
    }
  `)

  return (
    <div style={{display: 'flex', flexFlow: 'column', minHeight: '100vh'}}>
      <Header author={data.site.siteMetadata.author}/>
      <Navigation title={data.site.siteMetadata.title}/>
      {showHero && (
        <Hero image={heroImage} bgColor={heroBgColor} title={heroTitle}
              textColor={heroTextColor}
              subtitle={heroSubtitle} altText={heroAltText} size={heroSize}/>
      )}
      <main style={{flexGrow: '1'}}>{children}</main>
      <Footer/>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  heroImage: PropTypes.string,
  heroBgColor: PropTypes.string,
  heroTitle: PropTypes.string,
  heroSubtitle: PropTypes.string,
  heroAltText: PropTypes.string,
  heroTextColor: PropTypes.string,
  showHero: PropTypes.bool,
  heroSize: PropTypes.string,
}

Layout.defaultProps = {
  showHero: true,
  heroBgColor: 'white',
  heroSize: 'small',
  heroTextColor: 'var(--color-primary)',
}

export default Layout







