import React, {useState, useEffect, useRef} from "react"
import navigationStyle from "../less/navigation.module.less"
import BviLogo from "../images/bvi-logo.png"
import {Button} from "antd"
import {IntlContextConsumer, Link} from "gatsby-plugin-intl"
import Container from "./container"
import PropTypes from "prop-types"
import HiddenCssStyle from "../helpers/hiddencss.module.less"
import LocaleSelection from "./locale-selection";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import MenuOutlined from "@ant-design/icons/lib/icons/MenuOutlined";
import {useIntl} from "react-intl";

function Navigation(props) {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);

  const toggleMobileMenu = function () {
    document.body.style.overflow = mobileMenuActive ? "" : "hidden"
    setMobileMenuActive(!mobileMenuActive);
  };

  const useWindowUnloadEffect = (handler, callOnCleanup) => {
    const cb = useRef()

    cb.current = handler

    useEffect(() => {
      const handler = () => cb.current()

      window.addEventListener('beforeunload', handler)

      return () => {
        if (callOnCleanup) handler()

        window.removeEventListener('beforeunload', handler)
      }
    }, [cb])
  }

  useWindowUnloadEffect(() => {
    if (mobileMenuActive) {
      toggleMobileMenu();
    }
  }, true)

  const intl = useIntl();

  return (
    <div>
      <Container>
        <div className={navigationStyle.wrapper}>
          <div className={navigationStyle.logo}>
            <Link to="/home">
              <img src={BviLogo} alt={intl.formatMessage({id: 'alt_projectlogo'})} srcSet={`${BviLogo} 2x`}/>
            </Link>
            <h1
              className={HiddenCssStyle.visuallyHidden}>{props.title}</h1> {/* Leave this here for SEO purposes */}
          </div>
          <div className={navigationStyle.controls}>
            <nav role="navigation" aria-label="Main">

              <ul className={navigationStyle.navigation}>
                <li>
                  <Link to='/projects'>
                    <Button type='link' className={navigationStyle.button}>
                      {intl.formatMessage({id: 'nav_project_title'})}
                    </Button>
                  </Link>
                </li>
                <li>
                  <Link to="/quiz">
                    <Button type='link' className={navigationStyle.button}>
                      {intl.formatMessage({id: 'nav_quiz_title'})}
                    </Button>
                  </Link>
                </li>
              </ul>
            </nav>
            <IntlContextConsumer>
              {
                ({languages, language: currentLocale}) =>
                  <LocaleSelection localeSlugs={languages}
                                   activeLocaleSlug={currentLocale}
                                   isDropdown={true}/>
              }
            </IntlContextConsumer>
          </div>
          <div className={navigationStyle.mobileControls}>
            <Button
              onClick={toggleMobileMenu}
              icon={mobileMenuActive ?
                <CloseOutlined style={{fontSize: "20px"}}/> :
                <MenuOutlined style={{fontSize: "20px"}}/>}
              type='link'/>
          </div>
        </div>
      </Container>
      <nav
        className={mobileMenuActive ? `${navigationStyle.mobileContainer} ${navigationStyle.mobileContainerOpen}` : navigationStyle.mobileContainer}>
        <ul className={navigationStyle.mobileNavigation}>
          <li>
            <Link to='/projects'>
              <Button type='link'>
                {intl.formatMessage({id: 'nav_project_title'})}
              </Button>
            </Link>
          </li>
          <li>
            <Link to="/test">
              <Button type='link'>
                {intl.formatMessage({id: 'nav_quiz_title'})}
              </Button>
            </Link>
          </li>
          <li>
            <Link to="/news">
              <Button type='link'>
               {intl.formatMessage({id: 'nav_news_title'})}
              </Button>
            </Link>
          </li>
        </ul>
        <div className={navigationStyle.mobileLanguageContainer}>
          <IntlContextConsumer>
            {
              ({languages, language: currentLocale}) =>
                <LocaleSelection localeSlugs={languages}
                                 activeLocaleSlug={currentLocale}
                                 isDropdown={false}/>
            }
          </IntlContextConsumer>
        </div>
      </nav>
    </div>
  )
}

Navigation.propTypes = {
  title: PropTypes.string,
}

Navigation.defaultProps = {
  title: ``,
}

export default Navigation
