import React, { useEffect } from "react"
import HeroStyle from "../less/hero.module.less";
import Container from "./container";
import PropTypes from "prop-types";

const Hero = ({image, bgColor, title, subtitle, altText, textColor, size}) => {
  const ref = React.createRef();

  useEffect(() => {
    ref.current.style.setProperty("--hero-bg", bgColor);
  });


  return (
    <div className={HeroStyle.hero}
         ref={ref}
         style={{
           backgroundColor: bgColor,
           height: size === 'large' ? '353px' : '256px'
         }}>
      {/* center absolutely positioned img */}
      <div style={{position: 'absolute', left: '50%'}}>
        <div style={{position: 'relative', left: '-50%'}}>
          {/* Not using CSS background here so we can make this image responsive */}
          {
            image &&
            <>
              <div className={HeroStyle.fade}></div>
              <img src={image} srcSet={`${image} 2x`} alt={altText}/>
            </>
          }
        </div>
      </div>
      <Container>
        <div className={HeroStyle.text} style={{marginBottom: size === 'large' ? '100px': ''}}>
          <h2 style={{color: textColor}}>{title}</h2>
          <span style={{color: textColor}}>{subtitle}</span>
        </div>
      </Container>
    </div>
  );
}

Hero.propTypes = {
  image: PropTypes.string,
  bgColor: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  altText: PropTypes.string,
  textColor: PropTypes.string,
  size: PropTypes.string,
}

Hero.defaultProps = {
  image: '',
  size: 'small',
}

export default Hero
