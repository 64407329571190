import * as React from "react"
import {Button, Card, Tag} from "antd"
import cardStyle from "../less/projectcards.module.less"
import {Link} from "gatsby-plugin-intl"
import useIntl from "../helpers/useIntl"
import PropTypes from "prop-types";

const {Meta} = Card

const ProjectCard = ({title, description, categories, slug, coverImageLink, coverImageAlt}) => {
  const intl = useIntl()

  return (
    <Link to={'/project/' + slug} className={cardStyle.project}>
      <Card
        hoverable
        cover={<img alt={coverImageAlt}
                    src={coverImageLink}/>}>
        <Meta title={title}
              description={description}/>
        <div className={cardStyle.cardFooter}>
          {categories.map((category, i) => <Tag color="#f50" key={i}>
            {intl.translate(category, "title")}
          </Tag>)}

          <Button
            type={"link"}>{intl.formatMessage({id: 'general_readmore'})}</Button>
        </div>
      </Card>
    </Link>
  )
}

ProjectCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  coverImageLink: PropTypes.string,
  coverImageAlt: PropTypes.string,
  categories: PropTypes.array
}

ProjectCard.defaultProps = {
  categories: []
}

export default ProjectCard
