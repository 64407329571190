import * as React from "react"
import CardGridStyle from "../less/card-grid.module.less"

function CardGrid(props) {
  return (
    <div className={CardGridStyle.grid}>
      {props.children}
    </div>
  )
}

export default CardGrid;
