import React, {useState} from "react"
import {Button, Dropdown, Menu} from "antd";
import {DownOutlined} from "@ant-design/icons";
import PropTypes from 'prop-types';
import {changeLocale} from "gatsby-plugin-intl"

function LocaleSelection(props) {
  const [localeNames] = useState({
    nl: "NL",
    fr: "FR"
  });

  const menu = (
    <Menu>
      {
        props.localeSlugs.map(localeSlug => (
          <Menu.Item key={localeSlug}
                     onClick={() => changeLocale(localeSlug)}>
            {localeNames[localeSlug]}
          </Menu.Item>
        ))
      }
    </Menu>
  );

  if (props.isDropdown) {
    return (
      <div>
        <Dropdown overlay={menu}>
          <a className="ant-dropdown-link"
             href=''
             onClick={e => e.preventDefault()}>
            <span>{localeNames[props.activeLocaleSlug]}</span>
            <DownOutlined/>
          </a>
        </Dropdown>
      </div>
    )
  } else {
    return (
      props.localeSlugs.map((localeSlug) => (
        <Button
          key={localeSlug}
          type={props.activeLocaleSlug === localeSlug ? 'primary' : ''}
          onClick={() => changeLocale(localeSlug)}>
          {localeNames[localeSlug]}
        </Button>
      ))
    )
  }
}

LocaleSelection.propTypes = {
  localeSlugs: PropTypes.array,
  activeLocaleSlug: PropTypes.string,
  isDropdown: PropTypes.bool
};

LocaleSelection.defaultProps = {
  activeLocaleSlug: 'nl',
  isDropdown: true
};

export default LocaleSelection
