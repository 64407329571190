import PropTypes from "prop-types"
import React from "react"
import headerStyle from '../less/header.module.less'
import BxlLogo from "../images/bxl-logo.png";
import {useIntl} from "react-intl";

const Header = ({author}) => {
  const intl = useIntl();

  return (
    <header className={headerStyle.header}>
      <div className={headerStyle.logo}>
        <img src={BxlLogo}
             srcSet={`${BxlLogo} 2x`}
             alt={intl.formatMessage({id: 'alt_brusselslogo'})}/>
      </div>
      <div className={headerStyle.title}>
        <h2>{author}</h2>
      </div>
    </header>
  )
}

Header.propTypes = {
  author: PropTypes.string,
};

Header.defaultProps = {
  author: ``,
};

export default Header
